@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import url(https://fonts.googleapis.com/css?family=Dosis&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kufam&family=M+PLUS+Rounded+1c:wght@100&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./assets/blue-back2.jpg"); */
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #5fb4de;
}

.AddGift {
  position: relative;
  top: 5%;
}

.App {
  text-align: center;
  /* height: 100%; */
}

.ant-table-placeholder {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.ant-input {
  text-align: "center";
  width: "80%";
}

.ant-checkbox-group-item {
  color: white;
  float: left;
}

.password-spoof {
  -webkit-text-security: disc;
  -moz-text-security: disc;
  text-security: disc;
}

.AppWelcome {
  margin-top: 7%;
  /* font-family: 'Indie Flower', cursive; */
  /* font-family: 'Bowlby One', cursive; */
  font-family: 'M PLUS Rounded 1c', sans-serif;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  /* background-image: url("./assets/gifts.jpg");
  background-repeat: no-repeat;
  background-size: 8em; */
  /* background: #0c273b;  */
  /* position: fixed; 
  top: -50%; 
  left: -50%; 
  width: 200%; 
  height: 200%; */
}

.reset-pass-title {
  margin-top: 5%;
  /* font-family: 'Indie Flower', cursive; */
  font-family: 'Bowlby One', cursive;
  color: white;
  font-size: 1em;
  text-align: center;
}

.btn-submit {
  margin-left: 4px;
  background-color: #a1e0ff!important;
  border-radius: 40px;
  color: white!important;
  font-weight: bold
}

.btn-cancel {
  margin-left: 4px;
  background-color: #E46A5E!important;
  border-radius: 40px;
  color: white!important;
  font-weight: bold
}

.welcome-image {
  width: 100vw;
  height: 75%;
  /* background-position: center center; */
  position: absolute;  
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.7;
}

.BdayTitle {
  font-size: 35pt;
}

.add-group-button-div {
  position: absolute;
  left: 2px;
  top: 68px;
}

.BdayTitleDate {
  font-weight: bold;
  font-size: 44pt;
  color: #1890ff
}

@media only screen and (max-width: 600px) {
  .welcome-image {
    width: 100vw;
    height: 65%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .topBarMenu {
    position: absolute;
    left: 0;
  }
  .gift-description-modal {
    height: 100%!important;
    overflow: scroll!important;
  }
  .BdayTitle {
    font-size: 2em;
  }
  .BdayTitleDate {
    font-size: 1.5em!important;
  }
  .BdaySubitle {
    font-size: 1em;
  }
  .add-group-button {
    border-radius: 35px!important;
  }
  .add-group-button-div {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 80%;
    top: 85%;
    z-index: 999;
  }
}

.list-items {
  top: 70%
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.ant-card {
  border-radius: 25px!important;
  padding: 25px;
}

.App-header {
  background-color: #282c34;
  min-height: 21vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.AppFont {
  /* font-family: 'Indie Flower', cursive; */
  /* font-family: 'Bowlby One', cursive; */
  /* font-family: 'Kufam', cursive; */
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: bolder;
}

.NoteFont {
  /* font-family: 'Dosis', sans-serif; */
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.GiftCard {
  padding: 6px;
}

.MyList {
  padding: 55px;
}

.loading-mooadl {
  border: none;
}

.register-modal {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 8% auto;
  background-color: #0c273b;
  color: rgba(255, 255, 255, 0.85);
  padding: 0px;
  z-index: 1003;
}

.star {
  fill:  "#F6D55C";
}

.register-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1003;
}

.gift-description-modal {
  position: relative;
  height: 65%;
  width: 85%;
  margin: 8% auto;
  background-color: #0c273b;
  color: rgba(255, 255, 255, 0.85);
  padding: 0px;
  z-index: 1003;
}

.gift-description-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1003;
}

.group-add-modal {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 8% auto;
  background-color: #0c273b;
  color: rgba(255, 255, 255, 0.85);
  padding: 0px;
  z-index: 1003;
}

.group-add-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1003;
}

.invite-modal {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 8% auto;
  background-color: #0c273b;
  color: rgba(255, 255, 255, 0.85);
  padding: 0px;
  z-index: 1003;
}

.invite-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1003;
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, .2);
  margin: 16px 24px 16px 0;
  float: left;
}

ul {
  list-style-type: none;
}

.calendar-events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.calendar-events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.calendar-notes-month {
  text-align: center;
  font-size: 28px;
}

.calendar-notes-month section {
  font-size: 28px;
}

@-webkit-keyframes hinge {
  0% {
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: hinge;
          animation-name: hinge;
}

@-webkit-keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation: bounce 1s infinite linear;
          animation: bounce 1s infinite linear;
  transform-origin: center bottom;
}
